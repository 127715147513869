.header {
  z-index: var(--l8);
  background-color: var(--white);
  padding: 10px 48px 10px 10px;
  position: sticky;
  top: 0;
}

.nav-il {
  margin-left: 15px;
}

.clock {
  width: 24px;
  height: 24px;
  margin-right: 3px;
}

.clock-text {
  color: var(--green);
  cursor: default;
}

.nav-text {
  font-family: var(--font);
  font-weight: var(--fw7);
  font-size: var(--h_t);
}

.admin-name {
  cursor: default;
}

.admin-pro-div {
  filter: var(--shadow_1);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-left: 10px;
  overflow: hidden;
}

.admin-profile {
  width: 100%;
}

.head-drop {
  width: 24px;
  height: 24px;
}

.head-drop-op {
  z-index: var(--l8);
  background-color: var(--white);
  border-radius: var(--br_r);
  padding: 12px 60px 12px 15px;
  transition: all .3s;
  position: fixed;
  top: 70px;
  right: 35px;
}

.head-drop-op-t {
  top: -70px;
}

.head-drop-ops {
  color: var(--h_text);
  margin-bottom: 12px;
}

.head-drop-icon {
  width: 20px;
  height: 20px;
  margin-right: 7px;
}

.head-drop-text {
  font-weight: var(--fw6);
}

.logout-btn {
  color: var(--blue);
}

/*# sourceMappingURL=index.4a02fc64.css.map */
