
.header{
    position: sticky;
    top: 0;
    z-index: var(--l8);
    padding: 10px 48px 10px 10px;
    background-color: var(--white);
}
.nav-il{
    margin-left: 15px;
}
.clock{
    width: 24px;
    height: 24px;
    margin-right: 3px;
}
.clock-text{
    color: var(--green);
    cursor: default;
}
.nav-text{
    font-family: var(--font);
    font-weight: var(--fw7);
    font-size: var(--h_t);
}
.admin-name{
    cursor: default;
}
.admin-pro-div{
    width: 40px;
    height: 40px;
    margin-left: 10px;
    border-radius: 50%;
    overflow: hidden;
    filter: var(--shadow_1);
}
.admin-profile{
    width: 100%;
}

/* head drop */
.head-drop{
    width: 24px;
    height: 24px;
}
.head-drop-op{
    padding: 12px 60px 12px 15px;
    position: fixed;
    right: 35px;
    top: 70px;
    z-index: var(--l8);
    background-color: var(--white);
    border-radius: var(--br_r);
    transition: 0.3s;
}
.head-drop-op-t{
    top: -70px;
}
.head-drop-ops{
    margin-bottom: 12px;
    color: var(--h_text);
}
.head-drop-icon{
    margin-right: 7px;
    width: 20px;
    height: 20px;
}
.head-drop-text{
    font-weight: var(--fw6);
}
.logout-btn{
    color: var(--blue);
}